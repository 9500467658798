import React, { useEffect, useState } from "react"

const Fade = ({ show, children, conditionalClassName }) => {
  const [render, setRender] = useState(show)

  useEffect(() => {
    if (show) setRender(true)
  }, [show])

  const onAnimationEnd = () => {
    if (!show) setRender(false)
  }

  return (
    render && (
      <div
        className={`fade ${
          show ? conditionalClassName[0] : conditionalClassName[1]
        }`}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  )
}

export default Fade
