// ** @jsx jsx *
import React, { useState, useRef, useEffect } from "react"
import { jsx, Styled } from "theme-ui"
import Layout from "../components/Layout"
import Header from "../components/ui/Header"
import Product from "../components/Product"
import ProductList from "../components/ProductList"
import ProductListFavorite from "../components/ProductListFavorite"
import DownBar from "../components/ui/DownBar"
import slug from "../lib/slug"
import countapi from "countapi-js"
const Index = ({ data }) => {
  const scrollProductList = useRef(null)
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location &&
      window.location.hostname
    ) {
      countapi
        .hit(slug(window.location.hostname))
        .then(result => {
          console.log(result.value)
        })
        .catch(e => console.log(e))
    }
  }, [])
  const activeDownBar =
    data.sanityUserInterface == null
      ? true
      : data.sanityUserInterface.cartOptions == null
      ? true
      : data.sanityUserInterface.cartOptions.activeCart

  return (
    <Layout>
      <Header />
      <div
        sx={{
          display: "flex",
          width: "100%",
          // height: "60vh",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {data.sanityUserInterface &&
          data.sanityUserInterface.favoriteProduct &&
          data.sanityUserInterface.favoriteProduct.product && (
            <ProductListFavorite scrollProductList={scrollProductList} />
          )}
        <ProductList
          products={data.allSanityProduct.nodes}
          money={data.sanitySetting.money}
          scrollProductList={scrollProductList}
        />
        {activeDownBar && <DownBar />}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Data {
    allSanityProduct(
      filter: { otherOptionsProduct: { hiddenProduct: { ne: true } } }
      sort: { order: ASC, fields: [title] }
    ) {
      nodes {
        id
        title
        slug
        description
        sku
        price
        img {
          asset {
            metadata {
              dimensions {
                height
                width
              }
            }
            url
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }

        categories {
          id
          title
          slug
          icon {
            asset {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
              fixed(height: 25, width: 25) {
                base64

                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
        }

        quantityOptions {
          active
          hiddenAddQuantity
          quantityStep
        }
        discount {
          active

          priceNotReduced
        }
        multimediaOptions {
          active
          fyuse
          youtube
          listImages {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }

        variants {
          name
          tag

          list {
            img {
              asset {
                metadata {
                  dimensions {
                    height
                    width
                  }
                }
                url
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
              hotspot {
                height
                width
                x
                y
              }
            }

            quantityOptions {
              active
              hiddenAddQuantity
              quantityStep
            }
            multimediaOptions {
              active
              fyuse
              youtube
              listImages {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }

            description
            price
            tag
            title
            discount {
              active
              priceNotReduced
            }
          }
        }
        otherOptionsProduct {
          hiddenProduct
        }
      }
    }
    allSanityCategory(sort: { order: ASC, fields: [title] }) {
      nodes {
        title
        slug
        icon {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    sanitySetting {
      description
      logo {
        asset {
          url
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
      cover {
        asset {
          url
        }
      }
      title
      whatsapp {
        message
        phone
      }

      money
    }
    sanityUserInterface {
      customColors {
        background
        colorPrimary
        colorSecondary
        theme
      }
      favoriteProduct {
        title

        product {
          title
          slug
          description
          price
          sku
          img {
            asset {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }

          variants {
            name
            tag
            list {
              description
              price
              sku
              tag
              title
              img {
                asset {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                  }
                }
              }
            }
          }
        }
      }
      cartOptions {
        activeCart
      }
    }
  }
`
export default Index
