// ** @jsx jsx *
import React, { useContext } from "react"
import { jsx } from "theme-ui"
import { FiShoppingCart } from "react-icons/fi"
import { CartContext } from "../context/Context"
import Link from "./LinkDataGet.jsx"
import MiniImages from "./MiniImages"
import TotalCount from "../TotalCount.jsx"
const DownBar = () => {
  const { cart, setCart } = useContext(CartContext)

  return (
    <Link
      to="/carrito"
      sx={{
        position: "fixed",
        cursor: "pointer",
        bottom: 0,
        width: "100vw",
      }}
    >
      <div
        sx={{
          position: "relative",
          height: cart.length > 0 ? 60 : 5,
          width: "100%",
          backgroundColor: cart.length > 0 ? "backgroundSecondary" : "primary",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          border: "primarySimple",
          borderLeft: 0,
          borderRight: 0,
          borderBottom: 0,
        }}
      >
        <div sx={{ display: "flex", overflowX: "auto" }}>
          <MiniImages cart={cart} />
        </div>
        <div
          sx={{
            position: "absolute",
            top: -50,
            left: "50%",
            transform: "translate(-50%)",
            borderRadius: "16px 16px 0 0",
            width: 150,
            backgroundColor: "primary",
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FiShoppingCart
            sx={{ color: "background", fontSize: 3, marginRight: 1 }}
          />
          <h5 sx={{ fontFamily: "body", color: "background" }}>Ver carrito</h5>
        </div>
        <div
          sx={{
            position: "absolute",
            top: -40,
            left: ["78vw", "width2", "width3"],
            // transform: "translateX(-50%)",
          }}
        >
          <TotalCount />
        </div>
      </div>
      <style jsx="true" global="true">{`
        body {
          padding-bottom: ${cart.length > 0 ? "70px" : "0"};
        }
      `}</style>
    </Link>
  )
}

export default DownBar
