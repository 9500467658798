// ** @jsx jsx *
import React, { useState, useEffect } from "react"
import Product from "./Product"
import { jsx, Styled } from "theme-ui"
import { BsCaretDownFill, BsFillCaretUpFill } from "react-icons/bs"
import Fade from "./ui/Fade"
import { useSpring, animated } from "react-spring"
import { useStaticQuery, graphql } from "gatsby"
const ProductListFavorite = ({ scrollProductList }) => {
  const data = useStaticQuery(graphql`
    query dataProductListFavorite {
      sanitySetting {
        description
        logo {
          asset {
            url
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
        cover {
          asset {
            url
          }
        }
        title
        whatsapp {
          message
          phone
        }

        money
      }
      sanityUserInterface {
        customColors {
          background
          colorPrimary
          colorSecondary
          theme
        }
        favoriteProduct {
          title

          product {
            id
            title
            description
            price
            discount {
              active

              priceNotReduced
            }
            sku
            slug
            img {
              asset {
                fixed(width: 100, height: 100) {
                  base64

                  width
                  height
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
            variants {
              name
              tag
              list {
                description
                price
                sku
                tag
                title
                discount {
                  active
                  priceNotReduced
                }
                img {
                  asset {
                    fluid {
                      base64
                      aspectRatio
                      src
                      srcSet
                      srcWebp
                      srcSetWebp
                      sizes
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [products, setProducts] = useState([])
  const title =
    data.sanityUserInterface &&
    data.sanityUserInterface.favoriteProduct &&
    data.sanityUserInterface.favoriteProduct.title
  const sanitySetting = data.sanitySetting
  const [firstPressShowProducts, setFirstPressShowProducts] = useState(false)
  const [showProducts, setShowProducts] = useState(false)
  const [scrollShowProducts, setScrollShowProducts] = useState(true)
  let show = firstPressShowProducts ? showProducts : scrollShowProducts

  useEffect(() => {
    if (scrollProductList) {
      setTimeout(() => {
        try {
          scrollProductList.current._outerRef.addEventListener("scroll", e => {
            if (e.target.scrollTop > 100) {
              setScrollShowProducts(false)
            } else {
              setScrollShowProducts(true)
            }
          })
        } catch (error) {
          console.error(error)
          console.log(scrollProductList.current)
        }
      }, 1000)
    }
    if (
      data.sanityUserInterface &&
      data.sanityUserInterface.favoriteProduct &&
      data.sanityUserInterface.favoriteProduct.product
    ) {
      setProducts(data.sanityUserInterface.favoriteProduct.product)
    }
  }, [])

  //Animación
  const animation = useSpring({
    height: show ? 116 : 0,
    config: { duration: 400 },
  })
  return (
    <div
      sx={{
        width: "-webkit-fill-available",
      }}
    >
      {products.length > 0 && (
        <div>
          <div
            onClick={() => {
              setShowProducts(!showProducts)
              setFirstPressShowProducts(true)
            }}
            sx={{
              marginLeft: 4,
              marginRight: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {title && <Styled.h3>{title}</Styled.h3>}
            {!show ? <BsCaretDownFill /> : <BsFillCaretUpFill />}
          </div>
          <Fade
            show={show}
            conditionalClassName={[
              "fadeInProductListFavorite",
              "fadeOutProductListFavorite",
            ]}
          >
            <animated.div
              style={animation}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                maxWidth: "100vw",
                overflowX: "auto",
                overflowY: "hidden",
                borderBottom: "simpleOpacity",
              }}
            >
              {products.map((product, i) => {
                return (
                  product && (
                    <div
                      key={i}
                      sx={{
                        marginRight: 2,
                        // backgroundColor: "backgroundSecondary",
                        borderRadius: 2,
                      }}
                    >
                      <Product
                        product={product}
                        money={sanitySetting.money}
                        small
                        horizontal
                        simple
                      />
                    </div>
                  )
                )
              })}
            </animated.div>
          </Fade>
        </div>
      )}
    </div>
  )
}

export default ProductListFavorite
